import Highway from '@dogstudio/highway';
import Fade from './transition';

var ua = navigator.userAgent.toLowerCase();
var isSafari = false;

if (ua.indexOf('safari') != -1) {
  if (ua.indexOf('chrome') > -1) {
    console.log('its chrome');
  } else {
    isSafari = true;
    $('body').addClass('safari');
  }
}

if (!isSafari) {
  if ($(window).width() > 1000) {
    const H = new Highway.Core({
      transitions: {
        default: Fade,
      },
    });
  }
}
