import Highway from '@dogstudio/highway';
import { TimelineLite } from 'gsap';

class Fade extends Highway.Transition {
  in({ from, to, done }) {
    
    if ($(window).width() < 1000) {
      jQuery('.topnav ul').fadeOut(300);
      jQuery('.fa-bars').show();
      jQuery('.fa-times').hide();
      jQuery('.topnav').removeClass('active');
    }

    $('.logolarge').animate(
      {
        opacity: 0,
      },
      3000
    );

    $('#' + to.id + '').css('z-index', 3);
    $('#' + from.id + '').css('z-index', 0);
    $('#' + to.id + ' video').animate({ opacity: 0 }, 0);

    console.log('here:' + to.id + ' > ' + from.id);

    const tl = new TimelineLite();

    tl.fromTo(to, 0.5, { left: '-100%', top: '50%' }, { left: '0%' })
      .fromTo(
        to,
        0.5,
        { height: '2vh' },
        {
          height: '100vh',
          top: '0%',
          onComplete: function () {
            $('#' + to.id + ' video').animate({ opacity: 1 }, 300);
            from.remove();
            initPageAnimations(to.id);
            $('#' + to.id).addClass('relative');
            done();
          },
        }
      )
      .fromTo(to.children[0], 0.5, { opacity: 0 }, { opacity: 1 });
  }
  out({ from, done }) {
    if ($(window).width() < 1000) {
      jQuery('.topnav ul').css({ width: '100%' });
      jQuery('.fa-bars').hide();
      jQuery('.fa-times').show();
      jQuery('.topnav').addClass('active');
    }

    window.scrollTo(0, 0);
    $('#' + from.id).removeClass('relative');
    done();
  }
}
export default Fade;

function initPageAnimations(to) {
  var ua = navigator.userAgent.toLowerCase();
  var isSafari = false;

  if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
      console.log('its chrome');
      jQuery(".bcg, .bcg video").css({"width": "100%"});
    } else {
      isSafari = true;
    }
  }


  $('.scroll-hint').click(function () {
    var $el = $(this).parent(); //record the elem so you don't crawl the DOM everytime
    var bottom = $el.position().top + $el.outerHeight(true);
    window.scrollTo(0, bottom);
  });

  if (to == 'contact') {
    initContactForm();
  }

  var ctr = new ScrollMagic.Controller();

  $('video').each(function () {
    $(this).css(
{
      '-webkit-transform' : 'none',
      '-moz-transform'    : 'none',
      '-ms-transform'     : 'none',
      '-o-transform'      : 'none',
      'transform'         : 'none'

    }
    );
    console.log("this is a test");
    if ($(window).width() > 1000) {
      var v = document.getElementById($(this).attr('ID'));
      $(this).removeAttr('controls');
      if ($(window).width() > 1200) {
        $(this).width($(window).width());
        $(this).css({ width: 'auto' });
      }

      new ScrollMagic.Scene({
        triggerElement: '#' + $(this).attr('ID'),
        duration: 1000,
      })
        .addTo(ctr)
        .on('enter', function () {
          if (!isSafari) {
            v.play();
          }
        })
        .on('leave', function () {
          if (!isSafari) {
            v.pause();
          }
        });
    } else {
      $(this).css({
        display: 'none',
        position: 'fixed',
        left: '-200000000000px',
      });
    }
  });

  !(function (e) {
    var t = [];
    var r = [];
    var n = [];

    $('.slide').each(function () {
      t.push('#' + $(this).attr('ID'));
      r.push('#' + $(this).attr('ID') + ' header');
    });

    $('.content-block').each(function () {
      n.push('#' + $(this).attr('ID'));
    });

    var o = new ScrollMagic.Controller(),
      t,
      r,
      n;

    if (!Modernizr.touch) {
      r.forEach(function (e, t) {
        {
          var r = t + 1;
          new ScrollMagic.Scene({ triggerElement: e, offset: -95 })
            .setClassToggle('#slide0' + r, 'is-active')
            .addTo(o);
        }
      }),
        n.forEach(function (t, r) {
          {
            var n = e(t).attr('id');
            new ScrollMagic.Scene({ triggerElement: t, triggerHook: 0.75 })
              .setClassToggle('#' + n, 'is-active')
              .on('enter', function (o) {
                e('nav').attr('class', 'is-light');
              })
              .addTo(o);
          }
        }),
        t.forEach(function (t, r) {
          new ScrollMagic.Scene({ triggerElement: t })
            .on('enter', function (o) {
              e('nav').removeAttr('class');
            })
            .addTo(o);
        }),
        t.forEach(function (t, r) {
          {
            var n = e(t).find('.bcg');
            
            new ScrollMagic.Scene({
              triggerElement: t,
              triggerHook: 1,
              duration: '100%',
            })
              .setTween(
                TweenMax.from(n, 1, {
                  y: '-40%',
                  autoAlpha: 0.3,
                  ease: Power0.easeNone,
                })
              )
              .addTo(o);
          }
        });
      var a = new TimelineMax();
      a.to(e('#intro header, .scroll-hint'), 0.2, {
        autoAlpha: 0,
        ease: Power1.easeNone,
      })
        .to(e('#intro .bcg'), 1.4, { y: '20%', ease: Power1.easeOut }, '-=0.2')
        .to(e('#intro'), 1, { autoAlpha: 1, ease: Power1.easeNone }, '-=1.4');
      {
        new ScrollMagic.Scene({
          triggerElement: '#intro',
          triggerHook: 0,
          duration: '100%',
        })
          .setTween(a)
          .addTo(o);
      }
      o.scrollTo(function (e) {
        TweenMax.to(window, 1, { scrollTo: { y: e }, ease: Power1.easeInOut });
      }),
        e(document).on('click', "a[href^='#']", function (t) {
          var r = e(this).attr('href');
          e(r).length > 0 &&
            (t.preventDefault(),
            o.scrollTo(r),
            window.history &&
              window.history.pushState &&
              history.pushState('', document.title, r));
        });
    }
  })(jQuery);
}

function initContactForm() {
  !(function (e) {
    $('#progressbar').text('');
    $('form .callout').hide();
    $('form input[type=submit]').prop('disabled', true);
    $('form input[type=submit]').css({ background: '#cccccc' });

    $('form').submit(function (e) {
      e.preventDefault();
      if (getProgress()) {
        var fname = $('#firstname').val();
        var sname = $('#lastname').val();
        var websi = $('#website').val();
        var email = $('#email').val();
        var phone = $('#phone').val();
        var comme = $('#comment').val();
        var odata = 'No';
        var omark = 'No';
        if ($('#optin-data').is(':checked')) {
          odata = 'Yes';
        }
        if ($('#optin-marketing').is(':checked')) {
          omark = 'Yes';
        }

        var datastring = {
          fname: fname,
          sname: sname,
          websi: websi,
          email: email,
          phone: phone,
          comme: comme,
          odata: odata,
          omark: omark,
        };
        $.ajax({
          type: 'POST',
          url: '/protected/processcontactform.php',
          data: datastring,
          //dataType: 'json',
          success: function (data) {
            $('#progressbar').text(data);
            $('form input[type=submit]').prop('disabled', true);
            $('form input[type=submit]').css({ background: '#cccccc' });
            $('#firstname').val('');
            $('#lastname').val('');
            $('#email').val('');
            $('#phone').val('');
            $('#comment').val('');
          },
          error: function () {
            $('#progressbar').text(
              'We cannot process your request. Please try again at a later time'
            );
          },
        });
      }
    });

    $('form #optin-data').click(function () {
      //
      if ($(this).is(':checked')) {
        $('#formoptin i').css({ color: '#d62742' });
      } else {
        $('#formoptin i').css({ color: '#cccccc' });
      }
      getProgress();
    });
    $('form input[type=text],form input[type=email] , form textarea').blur(
      function () {
        var $parent = $(this).parent().parent();
        if ($parent.attr('ID') == 'formname') {
          if ($('#firstname').val() != '' && $('#lastname').val() != '') {
            $parent.find('i').css({ color: '#d62742' });
          } else {
            $parent.find('i').css({ color: '#cccccc' });
          }
        } else if ($parent.attr('ID') == 'formemail') {
          if ($('#email').val() != '') {
            if (/(.+)@(.+){2,}\.(.+){2,}/.test($('#email').val())) {
              $parent.find('i').css({ color: '#d62742' });
              $('#calloutemailcorrect').hide();
            } else {
              $parent.find('i').css({ color: '#cccccc' });
              $('#calloutemailcorrect').show();
            }
          } else {
            $parent.find('i').css({ color: '#cccccc' });
          }
        } else {
          if ($(this).val() != '') {
            $parent.find('i').css({ color: '#d62742' });
          } else {
            $parent.find('i').css({ color: '#cccccc' });
          }
        }

        getProgress();
      }
    );
  })(jQuery);
}

function getProgress() {
  var count = $('.required').length;
  var progress = 0;
  if ($('#firstname').val() != '') {
    progress++;
  }
  if ($('#lastname').val() != '') {
    progress++;
  }
  if (/(.+)@(.+){2,}\.(.+){2,}/.test($('#email').val())) {
    progress++;
  }
  if ($('#phone').val() != '') {
    progress++;
  }
  if ($('#comment').val() != '') {
    progress++;
  }
  if ($('#optin-data').is(':checked')) {
    progress++;
  }

  console.log(count + ' ' + progress);
  var prog = (progress / count) * 100;

  $('#progressbar').css({ width: prog + '%' });
  if (progress == count) {
    $('form input[type=submit]').prop('disabled', false);
    $('form input[type=submit]').addClass('submitactive');
    return true;
  } else {
    return false;
  }
}

!(function (e) {
  $(document).ready(function () {
    if (
      $('body').attr('ID') == 'page-contact' ||
      $('body').attr('ID') == 'page-home'
    ) {
      initContactForm();
    }
  });
})(jQuery);
